import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import applyImageFitOperation from 'editor/src/store/design/operation/applyImageFitOperation';
import getStructureIndexesByElementUuids from 'editor/src/store/design/selector/getStructureIndexesByElementUuids';
import selectElementOperation from 'editor/src/store/editor/operation/selectElementOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import hasSidebarTab from 'editor/src/store/editorModules/sidebar/selector/hasSidebarTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getVisibleWarnings from 'editor/src/store/editorModules/warnings/selector/getVisibleWarnings';
import { NotVisibleWarning, WarningType } from 'editor/src/store/editorModules/warnings/types';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import IconWarning2 from 'editor/src/component/Icon/IconWarning2';
import LinkButton from 'editor/src/component/LinkButton';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import styles from './index.module.scss';

export const canShow: CanShow = (state, { hasSelection }) => {
  const elementNotVisibleWarnings = state.editorModules.warnings.list.filter(
    (warning) => warning.type === WarningType.NotVisible,
  );
  return !hasSelection && !!elementNotVisibleWarnings.length && hasSidebarTab(state, TAB_NAMES.WARNINGS);
};

type Props = Pick<MenuItemProps, 'close' | 'isMobile'>;

function ButtonAllElementsWarnings({ isMobile }: Props) {
  const dispatch = useDispatch();
  const store = useStore();
  const visibleWarnings = useSelector(getVisibleWarnings);
  const elementNotVisibleWarnings = useMemo(() => {
    return visibleWarnings.filter((warning) => warning.type === WarningType.NotVisible) as NotVisibleWarning[];
  }, [visibleWarnings]);

  const openWarningTab = () => {
    const state = store.getState();
    // select first suitable element and open warning tab
    const selectedSpreadIndex = getCurrentSpreadIndex(state);
    let warningToSelect = elementNotVisibleWarnings.find((warning) => warning.spreadIndex === selectedSpreadIndex);
    if (!warningToSelect) {
      [warningToSelect] = elementNotVisibleWarnings;
    }

    batch(() => {
      if (warningToSelect) {
        dispatch(selectElementOperation(warningToSelect.uuid));
      }

      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.WARNINGS));
    });
  };

  if (!elementNotVisibleWarnings.length) {
    return null;
  }

  const fitElement = () => {
    const state = store.getState();
    const structureIndexes = getStructureIndexesByElementUuids(state, [elementNotVisibleWarnings[0].uuid]);
    dispatch(applyImageFitOperation(structureIndexes, true));
  };

  const onMobileIconClick = () => {
    if (isMobile) {
      openWarningTab();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonIndicator} onClick={onMobileIconClick}>
        <IconWarning2 className={styles.icon} />
      </div>
      {elementNotVisibleWarnings.length === 1 && !isMobile && (
        <Trans
          i18nKey="single-element-outside-the-area-topbar-warning"
          components={{
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            button: <LinkButton className={styles.actionButton} black={false} onClick={fitElement} />,
          }}
        />
      )}
      {elementNotVisibleWarnings.length > 1 && (
        <Trans
          i18nKey="elements-outside-the-area-topbar-warning"
          components={{
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            button: <LinkButton className={styles.actionButton} black={false} onClick={openWarningTab} />,
          }}
        />
      )}
    </div>
  );
}

export default React.memo(ButtonAllElementsWarnings);
