import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ImageManipulationPluginTypes, MediaElement } from 'editor/src/store/design/types';
import { DtfImageBackgroundWarning } from 'editor/src/store/editorModules/warnings/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { MimeType } from 'editor/src/store/gallery/types';
import { useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';

import LinkButton from 'editor/src/component/LinkButton';

import useImageManipulationButton from '../../EditImageTabContent/ImageManipulationContainer/ImageManipulationButton/useImageManipulationButton';

import AbstractWarningItem from './AbstractWarningItem';

import styles from './index.module.scss';

interface Props {
  warning: DtfImageBackgroundWarning;
  element: MediaElement;
  selected: boolean;
}

function DtfImageBackgroundWarningItem({ warning, element, selected }: Props) {
  const { t } = useTranslation();
  const { handleClick, imageModified } = useImageManipulationButton(ImageManipulationPluginTypes.BackgroundRemoval);

  const { removeBackgroundPlugin, galleryImage } = useSelector((state) => ({
    removeBackgroundPlugin: getPlugin(state, ImageManipulationPluginTypes.BackgroundRemoval),
    galleryImage: element?.type === 'image' ? getImageById(state, element.imageId) : undefined,
  }));

  if (element?.type !== 'image' || !element.imageId || imageModified || galleryImage?.type === MimeType.SVG) {
    return null;
  }

  return (
    <AbstractWarningItem warning={warning} element={element} selected={selected} title={t('Remove Background')}>
      {t('dtf-remove-background-warning')}
      {removeBackgroundPlugin && (
        <Trans
          i18nKey="dtf-remove-background-warning-action"
          components={{
            button: <LinkButton onClick={handleClick} className={styles.InlineActionButton} />,
          }}
        />
      )}
    </AbstractWarningItem>
  );
}

export default React.memo(DtfImageBackgroundWarningItem);
