/* eslint-disable no-restricted-syntax */
import {
  ControlUIType,
  MultiOptions,
  ProductUnAvailability,
  ProductVariation,
  SingleOptions,
  Product,
} from 'editor/src/store/variants/types';

import CheckboxElement from './ProductControls/elements/CheckboxElement';
import ColorElement from './ProductControls/elements/ColorElement';
import MaterialElement from './ProductControls/elements/MaterialElement';
import RadioElement from './ProductControls/elements/RadioElement';
import MultiControl from './ProductControls/multi/MultiControl';
import MultiControlSize from './ProductControls/multi/MultiControlSize';
import MultiControlTechnology from './ProductControls/multi/MultiControlTechnology';
import {
  MultiProductControlProps,
  ControlOptionProps,
  SingleProductControlProps,
} from './ProductControls/ProductControlProps';
import DropDownControl from './ProductControls/single/DropdownControl';
import SingleControl from './ProductControls/single/SingleControl';
import SingleControlTechnology from './ProductControls/single/SingleControlTechnology';

export function getMultiControl(controlUiType: ControlUIType): React.FC<MultiProductControlProps> {
  switch (controlUiType) {
    case 'size':
      return MultiControlSize;
    case 'technology':
      return MultiControlTechnology;
    default:
      return MultiControl;
  }
}

export function getSingleControl(controlUiType: ControlUIType): React.FC<SingleProductControlProps> {
  switch (controlUiType) {
    case 'dropdown':
      return DropDownControl;
    case 'technology':
      return SingleControlTechnology;
    default:
      return SingleControl;
  }
}

export function getControlElement(controlUiType: ControlUIType, multiMode: boolean): React.FC<ControlOptionProps> {
  switch (controlUiType) {
    case 'button':
      return RadioElement;
    case 'color':
      return ColorElement as React.FC<ControlOptionProps>;
    case 'material':
      return MaterialElement as React.FC<ControlOptionProps>;
    default:
      return multiMode ? CheckboxElement : RadioElement;
  }
}

function filterVariationsBasedOnControlKey(
  multiOptions: MultiOptions,
  singleOptions: SingleOptions,
  filteredVariations: ProductVariation[],
  controlKey: string,
) {
  return filteredVariations.filter((variation) => {
    for (const key in multiOptions) {
      if (key === controlKey) {
        continue;
      }
      if (!multiOptions[key].some((option) => variation[key] === option.value)) {
        return false;
      }
    }

    for (const key in singleOptions) {
      if (key === controlKey) {
        continue;
      }
      if (variation[key] !== singleOptions[key]) {
        return false;
      }
    }

    return true;
  });
}

export function getAvailableOptions(
  multiOptions: MultiOptions,
  singleOptions: SingleOptions,
  variations: ProductVariation[],
  controlsUIOrder: string[],
  unavailableProducts: Product['unavailableProducts'],
) {
  return controlsUIOrder.reduce<{ [key: string]: Set<string> }>((acc, controlKey) => {
    let filteredVariations = variations;
    if (Object.keys(unavailableProducts).length) {
      filteredVariations = variations.filter((variation) => !unavailableProducts[variation.productUid]);
    }

    if (!filteredVariations.length) {
      acc[controlKey] = new Set();
      return acc;
    }

    const availableVariations = filterVariationsBasedOnControlKey(
      multiOptions,
      singleOptions,
      filteredVariations,
      controlKey,
    );

    if (availableVariations.length) {
      acc[controlKey] = new Set(availableVariations.map((variation) => variation[controlKey]));
    }

    return acc;
  }, {});
}

export function getUnAvailableOptions(
  multiOptions: MultiOptions,
  singleOptions: SingleOptions,
  variations: ProductVariation[],
  controlsUIOrder: string[],
  unavailableProducts: Product['unavailableProducts'],
) {
  return controlsUIOrder.reduce<{ [key: string]: { [option: string]: ProductUnAvailability[] } }>((acc, controlKey) => {
    let filteredVariations = variations;
    if (Object.keys(unavailableProducts).length) {
      filteredVariations = variations.filter((variation) => !!unavailableProducts[variation.productUid]);
    }

    if (!filteredVariations.length) {
      acc[controlKey] = {};
      return acc;
    }

    const unAvailableVariations = filterVariationsBasedOnControlKey(
      multiOptions,
      singleOptions,
      filteredVariations,
      controlKey,
    );

    if (unAvailableVariations.length) {
      acc[controlKey] = unAvailableVariations.reduce(
        (accum, variation) => {
          const unAvailabilityReason = unavailableProducts[variation.productUid];
          accum[variation[controlKey]] = formatUnavailabilityReason(unAvailabilityReason);
          return accum;
        },
        {} as { [option: string]: ProductUnAvailability[] },
      );
    }

    return acc;
  }, {});
}

const formatUnavailabilityReason = (reason: ProductUnAvailability[] | true): ProductUnAvailability[] => {
  if (reason === true) {
    return ['region_availability'];
  }
  return reason;
};

const regionUnAvailabilityMessage = 'This product variant is not available in your region';
const dispatchSlaUnavailabilityMessage = 'This product is not available for TikTok';

export function getUnAvailableMessage(option: string, unavailableOptions?: { [key: string]: ProductUnAvailability[] }) {
  return unavailableOptions?.[option]?.map((unAvailabilityReason) =>
    unAvailabilityReason === 'region_availability' ? regionUnAvailabilityMessage : dispatchSlaUnavailabilityMessage,
  );
}
