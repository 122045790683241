import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { StructureIndex } from 'editor/src/store/design/types';
import selectElementOperation from 'editor/src/store/editor/operation/selectElementOperation';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import applyToMultipleMediaOperation from './applyToMultipleMediaOperation';
import fitMediaToSpreadOperation from './distribute/fitMediaToSpreadOperation';

const applyImageFitOperation =
  (structureIndexes: StructureIndex[], selectElement?: true) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    batch(() => {
      dispatch(saveUndoRedoStateOperation('fit element'));
      dispatch(applyToMultipleMediaOperation(structureIndexes, fitMediaToSpreadOperation));

      if (selectElement) {
        const state = getState();
        const element = getMediaElement(state, structureIndexes[0]);

        element?.uuid && dispatch(selectElementOperation(element.uuid));
      }
    });
  };

export default applyImageFitOperation;
