import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import applyImageFitOperation from 'editor/src/store/design/operation/applyImageFitOperation';
import getStructureIndexesByElementUuids from 'editor/src/store/design/selector/getStructureIndexesByElementUuids';
import { MediaElement } from 'editor/src/store/design/types';
import { NotVisibleWarning } from 'editor/src/store/editorModules/warnings/types';
import { useDispatch, useStore } from 'editor/src/store/hooks';

import LinkButton from 'editor/src/component/LinkButton';

import AbstractWarningItem from './AbstractWarningItem';

import styles from './index.module.scss';

interface Props {
  warning: NotVisibleWarning;
  element: MediaElement;
  selected: boolean;
}

function NotVisibleWarningItem({ warning, element, selected }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const fitElement = useCallback(() => {
    const state = store.getState();
    const structureIndexes = getStructureIndexesByElementUuids(state, [element.uuid]);
    dispatch(applyImageFitOperation(structureIndexes, true));
  }, [element]);

  return (
    <AbstractWarningItem
      warning={warning}
      element={element}
      selected={selected}
      title={t('Element outside of the print area')}
    >
      {t('element-outside-print-area-explanation')}
      {(element.type === 'image' || element.type === 'addon') && (
        <LinkButton className={styles.actionLinkButton} black={false} onClick={fitElement}>
          {t('Fit to print area')}
        </LinkButton>
      )}
    </AbstractWarningItem>
  );
}

export default React.memo(NotVisibleWarningItem);
