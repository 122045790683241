import applyImageOperation from 'editor/src/store/editor/operation/applyImageOperation';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { InboundPostMessage } from 'editor/src/util/postMessages/messages';

const applyHostImageOperation =
  ({ id }: InboundPostMessage['design.applyAsset']) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    if (!getImageById(state, id)) {
      throw new Error(`Can not apply image with id ${id}`);
    }

    dispatch(applyImageOperation(id));
  };

export default applyHostImageOperation;
