import cn from 'classnames';
import React from 'react';

import { WarningLevel } from 'editor/src/store/editorModules/warnings/types';

import IconCheck from 'editor/src/component/Icon/IconCheck';
import IconExclamation from 'editor/src/component/Icon/IconExclamation';
import IconInfo from 'editor/src/component/Icon/IconInfo';
import IconWarning2 from 'editor/src/component/Icon/IconWarning2';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  level: WarningLevel | undefined;
  className?: string;
  tooltip?: string;
  text?: string;
}

function IndicatorBadge({ level, className, tooltip, text }: Props) {
  let Icon;
  if (level === WarningLevel.Medium) {
    Icon = IconWarning2;
  } else if (level === WarningLevel.High) {
    Icon = IconExclamation;
  } else if (level === WarningLevel.Low) {
    Icon = IconInfo;
  } else {
    Icon = IconCheck;
  }

  const containerClassName = cn(
    styles.IndicatorBadge,
    className,
    { [styles.badge]: !text, [styles.hasText]: !!text },
    styles[level || 'Check'],
  );
  const content = (
    <>
      <Icon className={styles.icon} />
      <span>{text}</span>
    </>
  );

  if (tooltip) {
    return (
      <WithTooltip overlay={tooltip} className={containerClassName}>
        {content}
      </WithTooltip>
    );
  }

  return <div className={containerClassName}>{content}</div>;
}

export default React.memo(IndicatorBadge);
