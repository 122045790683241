import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { openPopupAction } from 'editor/src/store/app/slice';
import requestDesignDataOperation from 'editor/src/store/design/operation/requestDesignDataOperation';
import setLoadingWithTimeoutOperation from 'editor/src/store/editor/operation/setLoadingWithTimeoutOperation';
import getIsLoading from 'editor/src/store/editor/selector/getIsLoading';
import { WarningLevel, WarningType } from 'editor/src/store/editorModules/warnings/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import getECommerceProduct from 'editor/src/store/variants/selector/getECommerceProduct';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import { PopupName } from '../Popup/popups';

import getCurrentProductVariantPrice from './getCurrentProductVariantPrice';
import getExitWarningPopupData from './getExitWarningPopupData';

function useEcommerceHeader() {
  const dispatch = useDispatch();
  const isEditorHeaderEnabled = useSelector((state) => getHostSetting(state, 'enableEditorHeader'));
  const isEnableManualSaveDraft = useSelector((state) => getHostSetting(state, 'enableManualSaveDraft'));

  const { t } = useTranslation();
  const editorMode = useSelector((state) => getHostSetting(state, 'editorMode'));
  const isLoading = useSelector(getIsLoading);

  const currentProduct = useSelector(getECommerceProduct);
  const productPrice = useMemo(() => getCurrentProductVariantPrice(currentProduct), [currentProduct]);

  const handleHeaderBackButtonClick = useCallback(() => {
    if (!editorMode) {
      sendPostMessage('shopify.backButtonClick', undefined);
      dispatch(setLoadingWithTimeoutOperation());
      return;
    }

    const data = getExitWarningPopupData({ editorMode, t });

    dispatch(
      openPopupAction({
        open: PopupName.CONFIRMATION_POPUP,
        data,
      }),
    );
  }, [editorMode]);

  const handleHeaderContinueButtonClick = useCallback(() => {
    dispatch(
      requestDesignDataOperation({
        data: {
          checkWarnings: [
            { type: WarningType.EmptyPage, level: [WarningLevel.Medium] },
            { type: WarningType.EmptyDesign, level: [WarningLevel.Medium] },
            { type: WarningType.EmptyImageBox, level: [WarningLevel.Medium] },
            { type: WarningType.LowDPI, level: [WarningLevel.High] },
            { type: WarningType.SampleElementNotReplaced, level: [WarningLevel.High] },
            { type: WarningType.NotVisible, level: [WarningLevel.Medium] },
            { type: WarningType.OutOfRange, level: [WarningLevel.Medium] },
            { type: WarningType.DtfImageBackground, level: [WarningLevel.Low] },
          ],
          setIsLoadingOnConfirm: true,
        },
        callback: { type: 'shopify.proceedButtonClick' },
      }),
    );
  }, []);

  return {
    isEditorHeaderEnabled,
    isEnableManualSaveDraft,
    handleHeaderBackButtonClick,
    handleHeaderContinueButtonClick,
    productPrice,
    isLoading,
  };
}

export default useEcommerceHeader;
