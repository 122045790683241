import React from 'react';

import { ContentAddress, Coords, ElementSize, Page, Spread } from 'editor/src/store/design/types';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import EmptyContentPlaceholder from './EmptyContentPlaceholder';

interface Props {
  page: Page;
  spread: Spread;
  coords: Coords;
  canvasRotation: CanvasRotation;
  contentAddress?: ContentAddress;
}

const CONTENT_NAME_TO_IGNORE = new Set(['Content Sides', 'Content Side']); // for canvas and phonecase

function EmptyPagePlaceholder({ page, spread, coords, contentAddress, canvasRotation }: Props) {
  // TODO check element rotation and simplify performance part
  const isPageEmpty = (page: Page, spread: Spread) => {
    if (!page.groups.media?.length && page.page_nr) {
      return spread.pages[0].groups.media?.some((media) => {
        if (media.type === 'line') {
          return (media.x1 >= page.x || media.x2 >= page.x) && (media.y1 >= page.y || media.y2 >= page.y);
        }
        return media.x + media.width >= page.x && media.y + media.height >= page.y;
      });
    }
    return page.groups.media?.some((media) => {
      const maxX = page.x + page.width;
      const maxY = page.y + page.height;
      if (media.type === 'line') {
        return (media.x1 <= maxX || media.x2 <= maxX) && (media.y1 <= maxY || media.y2 <= maxY);
      }
      return media.x <= page.x + page.width && media.y <= page.y + page.height;
    });
  };
  const content = page?.groups.content;
  let focusedContent: ElementSize = page as ElementSize;
  if (contentAddress && contentAddress.pageIndex === page.page_nr) {
    focusedContent = content?.[contentAddress.contentIndex] as ElementSize;
  } else if (spread.pages.length > 1) {
    focusedContent = content?.find(
      (content) => content.type !== 'sample' && !CONTENT_NAME_TO_IGNORE.has(content.name),
    ) as ElementSize;
  }

  if (isPageEmpty(page, spread) || !focusedContent) {
    return null;
  }

  return (
    <EmptyContentPlaceholder
      key={page.page_nr}
      content={focusedContent}
      page={page}
      spreadCoords={coords}
      canvasRotation={canvasRotation}
    />
  );
}

export default React.memo(EmptyPagePlaceholder);
