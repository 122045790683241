import { captureException } from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';

import setDesignDataOperation from 'editor/src/store/design/operation/setDesignDataOperation';
import type { Thunk } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getExistingVariantsFromVariationGroups from 'editor/src/store/variants/helpers/getExistingVariantsFromVariationGroups';
import {
  setSelectedProductIdAction,
  setSelectedProductUidAction,
  updateMultipleProductItemAction,
} from 'editor/src/store/variants/slice';
import { MultipleProductItem } from 'editor/src/store/variants/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import { batch } from '../../batchedSubscribeEnhancer';

import setProductDataOperation from './setProductDataOperation';

const changeVariantProductOperation =
  (newProductId: string): Thunk =>
  (dispatch, getState: () => RootState) => {
    const state = getState();
    const { products, selectedProductId } = state.variants;
    if (!products || !selectedProductId) {
      return;
    }
    const activeProduct = products[selectedProductId];
    const newActiveProduct = products[newProductId];

    if (!activeProduct || !newActiveProduct) {
      captureException(new Error('No selectedProductId in multiple products set for switch operation'), {
        extra: {
          products,
          selectedProductId,
          newActiveProduct,
        },
      });
      return;
    }

    batch(() => {
      const currentVariationGroups = state.variants.variationGroups;
      const currentExistingVariants = getExistingVariantsFromVariationGroups(state.variants.variationGroups);

      // update current selected option in the list
      dispatch(
        updateMultipleProductItemAction({
          productId: selectedProductId,
          data: { variantGroups: currentVariationGroups, existingVariants: currentExistingVariants },
        }),
      );

      dispatch(setSelectedProductIdAction(newProductId));
      const clonedNewActiveProduct: MultipleProductItem = cloneDeep(newActiveProduct);
      void dispatch(setProductDataOperation(clonedNewActiveProduct));

      if (clonedNewActiveProduct.existingVariants?.length) {
        const variantsWithDesignData = clonedNewActiveProduct.existingVariants.find((variant) => variant.designData);
        if (variantsWithDesignData && variantsWithDesignData.designData) {
          void dispatch(setDesignDataOperation(variantsWithDesignData.designData));
          void dispatch(setSelectedProductUidAction(variantsWithDesignData.designData.product_uid));
        }
      }

      sendPostMessage('variants.productChanged', { productId: newProductId });
      // TODO consider switchToProduct operation
    });
  };

export default changeVariantProductOperation;
