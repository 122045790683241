import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';
import IconBin from 'editor/src/component/Icon/IconBin';

import styles from './index.module.scss';

interface Props {
  onRemove: () => void;
}

function RemoveImageButton({ onRemove }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.removeImageButtonWrap}>
      <Button secondary className={styles.removeImageButton} noPadding onClick={onRemove} stopPropagation>
        <IconBin /> {t('editor-remove-image')}
      </Button>
    </div>
  );
}

export default React.memo(RemoveImageButton);
