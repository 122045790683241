import { captureException } from '@sentry/react';

import { ECommerceProduct } from 'editor/src/store/variants/types';

import { ProductOption, Variant } from 'product-personalizer/src/utils/productTypes';

interface Option {
  name: string;
  values: string[];
}

interface PartialVariant {
  optionValues: string[];
}

export interface ValueWithDisabled {
  name: string;
  disabled: boolean;
}

export interface OptionWithDisabled extends Omit<ProductOption, 'values'> {
  values: ValueWithDisabled[];
}

export function getFormattedVariantOptions(
  options: Option[],
  variants: PartialVariant[],
  selectedVariant: PartialVariant,
): OptionWithDisabled[] {
  if (!options.length || !variants.length || !selectedVariant) {
    return [];
  }
  const selectedOptions = selectedVariant.optionValues.map((value) => {
    const name = options.find((option) => option.values.includes(value))?.name;
    return { name, value };
  });

  const updatedOptions: OptionWithDisabled[] = options.map((option, optionIndex) => {
    const updatedValues = option.values.map((value) => {
      // always allow to switch from the first row of options
      if (optionIndex === 0) {
        return { name: value, disabled: false };
      }

      const isSelected = selectedVariant.optionValues.includes(value);
      if (isSelected) {
        return { name: value, disabled: false };
      }

      const isVariantAvailableFromSelected = findVariantByOptionValues(
        variants,
        selectedOptions.map((selectedOption) => {
          return selectedOption.name === option.name ? value : selectedOption.value;
        }),
      );
      let disabled = !isVariantAvailableFromSelected;

      // we should not block the option if the level is not the last one and we have some variants combinations there
      if (optionIndex < options.length - 1 && disabled) {
        const separator = '&__&';
        const allVariantsStringified = variants.map((variant) => {
          return variant.optionValues.join(separator);
        });

        const currentOptionValuePrefix = [
          selectedVariant.optionValues.slice(0, optionIndex).join(separator),
          value,
        ].join(separator);

        disabled = !allVariantsStringified.some((variantStringified) =>
          variantStringified.startsWith(currentOptionValuePrefix),
        );
      }

      return { name: value, disabled };
    });

    return { ...option, values: updatedValues };
  });

  return updatedOptions;
}

function findVariantByOptionValues(variants: PartialVariant[], optionValues: string[]): boolean {
  return variants.some((variant) => optionValues.every((value) => variant.optionValues.includes(value)));
}

export function getSelectedVariant(variants: Variant[], currentSelectedVariant: Variant, index: number, value: string) {
  return (
    variants.find((variant) =>
      variant.optionValues.every((optionValue, i) =>
        i === index ? optionValue === value : optionValue === currentSelectedVariant.optionValues[i],
      ),
    ) ?? variants.find((variant) => variant.optionValues[index] === value) // when the current combination is not available, try to find a variant with the selected value
  );
}

export function getChangedEcommerceProductOptions(
  currentProduct: ECommerceProduct,
  previousProduct: ECommerceProduct | undefined,
) {
  if (!previousProduct) {
    // if there was no previous product -> all options changed
    return currentProduct.options.map((option) => option.name);
  }

  const currentVariant = currentProduct.variants.find((variant) => variant.id === currentProduct.selectedVariantId);
  const previousVariant = previousProduct.variants.find((variant) => variant.id === previousProduct.selectedVariantId);

  if (!currentVariant || !previousVariant) {
    captureException(
      `Can't find variants with ids ${currentProduct.selectedVariantId}, ${previousProduct.selectedVariantId}`,
      {
        extra: { currentProduct, previousProduct },
      },
    );
    return [];
  }

  const changedOptions: string[] = [];
  currentVariant.optionValues.forEach((option, index) => {
    const optionChanged = option !== previousVariant.optionValues[index];
    if (optionChanged) {
      changedOptions.push(currentProduct.options[index].name);
    }
  });

  return changedOptions;
}
