/* eslint-disable max-len */
export const mediumWarningIcon =
  '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<rect width="20" height="20" rx="10" fill="#D48C06" />\n' +
  '<path d="M4.64778 14.4706C4.41444 14.4706 4.23711 14.3684 4.11578 14.164C3.99398 13.9601 3.99141 13.7561 4.10808 13.5522L9.47498 4.3059C9.59164 4.10197 9.76664 4 9.99998 4C10.2333 4 10.4083 4.10197 10.525 4.3059L15.8919 13.5522C16.0085 13.7561 16.006 13.9601 15.8842 14.164C15.7628 14.3684 15.5855 14.4706 15.3522 14.4706H4.64778ZM10.0147 8.0684C9.84948 8.0684 9.70598 8.1293 9.58418 8.2511C9.46284 8.37243 9.40218 8.5157 9.40218 8.6809V10.314C9.40218 10.4797 9.46284 10.6232 9.58418 10.7445C9.70598 10.8663 9.84948 10.9272 10.0147 10.9272C10.1799 10.9272 10.3231 10.8663 10.4445 10.7445C10.5663 10.6232 10.6272 10.4797 10.6272 10.314V8.6809C10.6272 8.5157 10.5663 8.37243 10.4445 8.2511C10.3231 8.1293 10.1799 8.0684 10.0147 8.0684ZM10.0147 12.7206C10.1799 12.7206 10.3231 12.6599 10.4445 12.5386C10.5663 12.4168 10.6272 12.2733 10.6272 12.1081C10.6272 11.9429 10.5663 11.7994 10.4445 11.6776C10.3231 11.5563 10.1799 11.4956 10.0147 11.4956C9.84948 11.4956 9.70598 11.5563 9.58418 11.6776C9.46284 11.7994 9.40218 11.9429 9.40218 12.1081C9.40218 12.2733 9.46284 12.4168 9.58418 12.5386C9.70598 12.6599 9.84948 12.7206 10.0147 12.7206Z" fill="white"/>\n' +
  '</svg>\n';

export const highWarningIcon =
  '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<rect width="20" height="20" rx="10" fill="#D0412D"/>\n' +
  '<path d="M9.99997 10.5545C10.1652 10.5545 10.3087 10.4936 10.4305 10.3718C10.5518 10.2505 10.6125 10.1072 10.6125 9.94199V7.69569C10.6125 7.53049 10.5518 7.38956 10.4305 7.27289C10.3087 7.15623 10.1652 7.09789 9.99997 7.09789C9.83477 7.09789 9.69127 7.15879 9.56947 7.28059C9.44814 7.40193 9.38747 7.54519 9.38747 7.71039V9.95669C9.38747 10.1219 9.44814 10.2628 9.56947 10.3795C9.69127 10.4962 9.83477 10.5545 9.99997 10.5545ZM9.99997 12.9317C10.1652 12.9317 10.3087 12.8757 10.4305 12.7637C10.5518 12.6517 10.6125 12.5033 10.6125 12.3185C10.6125 12.1533 10.5518 12.01 10.4305 11.8887C10.3087 11.7674 10.1652 11.7067 9.99997 11.7067C9.83477 11.7067 9.69127 11.7674 9.56947 11.8887C9.44814 12.01 9.38747 12.1533 9.38747 12.3185C9.38747 12.5033 9.44814 12.6517 9.56947 12.7637C9.69127 12.8757 9.83477 12.9317 9.99997 12.9317ZM9.99997 15.8332C9.1931 15.8332 8.43477 15.6801 7.72497 15.374C7.01517 15.0679 6.39777 14.6523 5.87277 14.1273C5.34777 13.6023 4.9322 12.9849 4.62607 12.2751C4.31994 11.5653 4.16687 10.807 4.16687 10.0001C4.16687 9.19323 4.31994 8.43489 4.62607 7.72509C4.9322 7.01529 5.34777 6.39789 5.87277 5.87289C6.39777 5.34789 7.01517 4.93233 7.72497 4.62619C8.43477 4.32006 9.1931 4.16699 9.99997 4.16699C10.8068 4.16699 11.5652 4.32006 12.275 4.62619C12.9848 4.93233 13.6022 5.34789 14.1272 5.87289C14.6522 6.39789 15.0677 7.01529 15.3739 7.72509C15.68 8.43489 15.8331 9.19323 15.8331 10.0001C15.8331 10.807 15.68 11.5653 15.3739 12.2751C15.0677 12.9849 14.6522 13.6023 14.1272 14.1273C13.6022 14.6523 12.9848 15.0679 12.275 15.374C11.5652 15.6801 10.8068 15.8332 9.99997 15.8332Z" fill="white"/>\n' +
  '</svg>\n';

export const lowWarningIcon =
  '<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<rect width="24" height="24" rx="12" fill="#007CB4"/>\n' +
  '<path d="M12 17C12.2833 17 12.521 16.904 12.713 16.712C12.9043 16.5207 13 16.2833 13 16V11.975C13 11.6917 12.9043 11.4583 12.713 11.275C12.521 11.0917 12.2833 11 12 11C11.7167 11 11.4793 11.0957 11.288 11.287C11.096 11.479 11 11.7167 11 12V16.025C11 16.3083 11.096 16.5417 11.288 16.725C11.4793 16.9083 11.7167 17 12 17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.9043 8.52067 13 8.28333 13 8C13 7.71667 12.9043 7.479 12.713 7.287C12.521 7.09567 12.2833 7 12 7C11.7167 7 11.4793 7.09567 11.288 7.287C11.096 7.479 11 7.71667 11 8C11 8.28333 11.096 8.52067 11.288 8.712C11.4793 8.904 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z" fill="white"/>\n' +
  '</svg>\n';
