import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import digitizationGuidelinesFeeImg from 'editor/src/static/digitization-guidelines-fee.png';
import digitizationGuidelinesThreadsImg from 'editor/src/static/digitization-guidelines-threads.png';

import RichTooltip from 'editor/src/component/RichTooltip';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import DigitizationColorGuidelines from './DigitizationColorGuidelines';
import { isDigitizationGuidelinesTouched, setDigitizationGuidelinesTouched } from './utils';

import styles from './index.module.scss';

interface Props {
  guidelinesInfo: string;
  priceInfo: string;
}
function DigitizationGuidelines({ guidelinesInfo, priceInfo }: Props) {
  const designGuidelinesBtnRef = useRef<HTMLButtonElement>(null);
  const priceBtnRef = useRef<HTMLButtonElement>(null);
  const [designGuidelinesTooltipOpen, setDesignGuidelinesTooltipOpen] = React.useState(false);
  const [priceTooltipOpen, setPriceTooltipOpen] = React.useState(false);
  const digitizationGuidelinesTouchedRef = useRef(isDigitizationGuidelinesTouched());
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  // after 1 second, a rich tooltip is displayed by default
  useEffect(() => {
    if (digitizationGuidelinesTouchedRef.current) {
      return;
    }

    setTimeout(() => {
      if (!designGuidelinesTooltipOpen) {
        setDesignGuidelinesTooltipOpen(true);
      }

      setDigitizationGuidelinesTouched();
    }, 1000);
  }, []);

  const toggleDesignGuidelinesTooltip = () => {
    setDesignGuidelinesTooltipOpen(!designGuidelinesTooltipOpen);
  };

  const closeDesignGuidelinesTooltip = () => {
    setDesignGuidelinesTooltipOpen(false);
  };

  const togglePriceTooltip = () => {
    setPriceTooltipOpen(!priceTooltipOpen);
  };

  const closePriceTooltip = () => {
    setPriceTooltipOpen(false);
  };

  if (isMobile) {
    return null;
  }

  return (
    <div className={cn(styles.infoBoxContainer)}>
      <div className={styles.infoBox}>
        <Trans
          i18nKey="digitization-guidelines-info"
          components={{
            guidelinesTooltipButton: (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button
                ref={designGuidelinesBtnRef}
                className={styles.linkButton}
                onClick={toggleDesignGuidelinesTooltip}
              />
            ),
            priceTooltipButton: (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button ref={priceBtnRef} className={styles.linkButton} onClick={togglePriceTooltip} />
            ),
          }}
        />
      </div>
      <RichTooltip
        title={t('Embroidery design guidelines')}
        isVisible={designGuidelinesTooltipOpen}
        className={styles.guidelinesInfoTooltip}
        triggerBtnRef={designGuidelinesBtnRef}
        onClose={closeDesignGuidelinesTooltip}
        pointerOffset={24}
      >
        <Trans
          i18nKey="why-should-choose-embroidery"
          components={{
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            image: <img src={digitizationGuidelinesThreadsImg} alt={t('Stitches type')} />,
            ul: <ul />,
            li: <li />,
          }}
        />
        <p>
          <strong>{t('What colors can I use?')}</strong>
        </p>
        <DigitizationColorGuidelines />
        <p>
          <strong>{t('Guidelines for embroidery file creation')}</strong>
        </p>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: guidelinesInfo }} />
      </RichTooltip>
      <RichTooltip
        title={t('Digitization')}
        isVisible={priceTooltipOpen}
        className={styles.priceInfoTooltip}
        triggerBtnRef={priceBtnRef}
        onClose={closePriceTooltip}
        pointerOffset={93}
      >
        <Trans
          i18nKey="digitization-and-fee-tooltip-info"
          components={{
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            image: <img src={digitizationGuidelinesFeeImg} alt={t('Digitization')} />,
            imageCaption: <div className={styles.imageCaption} />,
            ul: <ul />,
            li: <li />,
          }}
        />
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: priceInfo }} />
      </RichTooltip>
    </div>
  );
}

export default React.memo(DigitizationGuidelines);
