import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PrintTechnology } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import InfoBanner from 'editor/src/component/Banners/InfoBanner';
import IconInfoFilled from 'editor/src/component/Icon/IconInfoFilled';

import styles from './index.module.scss';

interface Props {
  selectedTechnics: string[];
}
function PrintTechnicInfoBanner({ selectedTechnics }: Props) {
  const { t } = useTranslation();
  const variationGroups = useSelector((state) => state.variants.variationGroups);

  const COTTON_FABRIC = 'cotton';
  const productFabric = useMemo(
    () => [
      ...new Set(
        variationGroups?.flatMap(({ variationsInfo }) =>
          variationsInfo.flatMap(({ variation }) => variation.primaryFabricType),
        ),
      ),
    ],
    [variationGroups],
  );

  let printTechnicSuggestion = '';

  if (productFabric[0] === COTTON_FABRIC && !selectedTechnics.includes(PrintTechnology.DTG.toLowerCase())) {
    printTechnicSuggestion = t('dtg-print-technic-suggestion');
  }

  if (productFabric[0] !== COTTON_FABRIC && !selectedTechnics.includes(PrintTechnology.DTF.toLowerCase())) {
    printTechnicSuggestion = t('dtf-print-technic-suggestion');
  }

  if (productFabric.length !== 1 || !productFabric[0] || !printTechnicSuggestion) {
    return null;
  }

  return (
    <InfoBanner icon={<IconInfoFilled />} className={cn(styles.printTechnicInfoBanner, 'cy-print-technic-info-banner')}>
      {printTechnicSuggestion}
    </InfoBanner>
  );
}

export default React.memo(PrintTechnicInfoBanner);
