import cn from 'classnames';
import React from 'react';

import IconCross from 'editor/src/component/Icon/IconCross';

import styles from './index.module.scss';

interface Props {
  icon: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  onDismiss?: () => void;
}

function InfoBanner({ icon, onDismiss, children, className }: Props) {
  return (
    <div className={cn(styles.infoBanner, className, 'cy-info-banner')}>
      {icon}
      <div className={styles.description}>{children}</div>
      {onDismiss && (
        <div onClick={onDismiss}>
          <IconCross className={cn(styles.icon, styles.iconClose)} />
        </div>
      )}
    </div>
  );
}

export default React.memo(InfoBanner);
