import React from 'react';

import setActiveAddLayerDropdownOperation from 'editor/src/store/app/operation/setActiveAddLayerDropdownOperation';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import RemoveImageButton from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/ImageListItem/RemoveImageButton';
import DropDown from 'editor/src/component/DropDown';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  imageId: string;
  noRemove: boolean;
  onRemove: () => void;
}

function MoreInfoDropdown({ imageId, noRemove, onRemove }: Props) {
  const dispatch = useDispatch();

  const image = useSelector((state) => {
    return getImageById(state, imageId);
  });

  const closeDropdown = () => {
    dispatch(setActiveAddLayerDropdownOperation(undefined));
  };

  const name = image?.name;
  return (
    <DropDown isVisible className={styles.dropDown} onClickOutside={closeDropdown} openPosition="right-fromtop">
      {name && (
        <div className={styles.imageInfo}>
          <WithTooltip overlay={name} placement="top">
            <div className={styles.imageInfoText}>{name}</div>
          </WithTooltip>
        </div>
      )}
      {!noRemove && <RemoveImageButton onRemove={onRemove} />}
    </DropDown>
  );
}

export default React.memo(MoreInfoDropdown);
