import { ImageManipulationPluginTypes, MediaImage } from 'editor/src/store/design/types';
import { DtfImageBackgroundWarning, WarningLevel, WarningType } from 'editor/src/store/editorModules/warnings/types';
import { GalleryImage, MimeType } from 'editor/src/store/gallery/types';
import { getImageManipulationPluginData } from 'editor/src/store/plugins/utils/imageManipulationUtils';

function getDtfImageBackgroundWarning(
  element: MediaImage,
  spreadIndex: number,
  galleryImage: GalleryImage | undefined,
): DtfImageBackgroundWarning | undefined {
  const imageManipulationPluginData = getImageManipulationPluginData(
    ImageManipulationPluginTypes.BackgroundRemoval,
    element as Partial<MediaImage>,
  );

  if (imageManipulationPluginData?.imageModified || galleryImage?.type === MimeType.SVG) {
    return undefined;
  }

  return {
    type: WarningType.DtfImageBackground,
    uuid: element.uuid,
    spreadIndex,
    level: WarningLevel.Low,
  };
}

export default getDtfImageBackgroundWarning;
