import setSpreadBackgroundsOperation from 'editor/src/store/design/operation/setSpreadBackgroundsOperation';
import setSpreadForegroundsOperation from 'editor/src/store/design/operation/setSpreadForegroundsOperation';
import { setDesignDataAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getDesignKey, { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import { batch } from '../../batchedSubscribeEnhancer';

import sendProductSwitchedOperation from './sendProductSwitchedOperation';

const displaySelectedVariationOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) =>
  batch(() => {
    const state = getState();
    const { variationGroups, spreadImages, selectedGroupKey } = state.variants;
    const selectedGroup = variationGroups.find((group) => group.key === selectedGroupKey);
    if (!selectedGroup) {
      return;
    }

    const variant =
      selectedGroup.variationsInfo.find((variantInfo) => !!variantInfo.designData) || selectedGroup.variationsInfo[0];

    if (variant.designData) {
      const currentDesignKey = state.design.designData ? getDesignKeyFromDesign(state.design.designData) : undefined;
      const newDesignKey = getDesignKeyFromDesign(variant.designData);
      if (newDesignKey !== currentDesignKey) {
        dispatch(sendProductSwitchedOperation(variant));
      }

      dispatch(setDesignDataAction(variant.designData));
    }

    const spreadImage = spreadImages[getDesignKey(variant.variation.productUid, variant)];
    if (spreadImage) {
      void dispatch(
        setSpreadBackgroundsOperation({
          productUid: variant.variation.productUid,
          spreads: spreadImage.spreadBackgrounds,
        }),
      );
      void dispatch(
        setSpreadForegroundsOperation({
          productUid: variant.variation.productUid,
          spreads: spreadImage.spreadForegrounds,
        }),
      );
    }
  });

export default displaySelectedVariationOperation;
