import cn from 'classnames';
import React from 'react';

import setActiveAddLayerDropdownOperation from 'editor/src/store/app/operation/setActiveAddLayerDropdownOperation';
import isAddLayerDropdownVisible from 'editor/src/store/app/selector/isAddLayerDropdownVisible';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import IconMoreHorizontal from 'editor/src/component/Icon/IconMoreHorizontal';

import MoreInfoDropdown from './MoreInfoDropdown';

import styles from './index.module.scss';

interface Props {
  imageId: string;
  isButtonVisible: boolean;
  noRemove: boolean;
  onRemove: () => void;
}

const DROPDOWN_NAME_PREFIX = 'imageInfo';

function MoreInfoImageButton({ imageId, onRemove, isButtonVisible, noRemove }: Props) {
  const dispatch = useDispatch();

  const getDropdownName = () => {
    return `${DROPDOWN_NAME_PREFIX}_${imageId}`;
  };

  const isDropdownVisible = useSelector((state) => isAddLayerDropdownVisible(state, getDropdownName()));

  const onClick = () => {
    dispatch(setActiveAddLayerDropdownOperation(getDropdownName()));
  };

  const closeMoreMenu = () => {
    dispatch(setActiveAddLayerDropdownOperation(undefined));
  };

  const onRemoveClicked = () => {
    onRemove();
    closeMoreMenu();
  };

  return (
    <div className={styles.imageButtonWrap}>
      {isDropdownVisible && <MoreInfoDropdown imageId={imageId} noRemove={noRemove} onRemove={onRemoveClicked} />}
      <Button
        secondary
        className={cn(styles.imageButton, { [styles.hidden]: !isButtonVisible })}
        noPadding
        onClick={onClick}
        stopPropagation
      >
        <IconMoreHorizontal />
      </Button>
    </div>
  );
}

export default React.memo(MoreInfoImageButton);
