import polygonClipping from 'polygon-clipping';

import { Coords, MediaElement, MediaLine } from 'editor/src/store/design/types';
import { NotVisibleWarning, WarningLevel, WarningType } from 'editor/src/store/editorModules/warnings/types';

import doSegmentsIntersect from 'editor/src/util/2d/doSegmentsIntersect';
import { Point } from 'editor/src/util/2d/types';
import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

function isPointInsideSpread(x: number, y: number, spread: Point, spreadWidth: number, spreadHeight: number) {
  return x >= spread.x && x <= spread.x + spreadWidth && y >= spread.y && y <= spread.y + spreadHeight;
}

const REQUIRED_VISIBLE_AREA_RATE = 0.25; // 25%

function isSegmentIntersectWithSpread(
  point1: [number, number],
  point2: [number, number],
  spread: Point,
  spreadWidth: number,
  spreadHeight: number,
) {
  return (
    doSegmentsIntersect(
      point1[0],
      point1[1],
      point2[0],
      point2[1],
      spread.x,
      spread.y,
      spread.x + spreadWidth,
      spread.y,
    ) ||
    doSegmentsIntersect(
      point1[0],
      point1[1],
      point2[0],
      point2[1],
      spread.x + spreadWidth,
      spread.y,
      spread.x + spreadWidth,
      spread.y + spreadHeight,
    ) ||
    doSegmentsIntersect(
      point1[0],
      point1[1],
      point2[0],
      point2[1],
      spread.x + spreadWidth,
      spread.y + spreadHeight,
      spread.x,
      spread.y + spreadHeight,
    ) ||
    doSegmentsIntersect(
      point1[0],
      point1[1],
      point2[0],
      point2[1],
      spread.x,
      spread.y + spreadHeight,
      spread.x,
      spread.y,
    )
  );
}

export function isLineVisibleInSpread(
  element: MediaLine,
  spreadCoords: Coords,
  spreadWidth: number,
  spreadHeight: number,
) {
  // then we check if one point of the line is in the spread
  const coords = { x: spreadCoords.left, y: spreadCoords.top };
  if (
    isPointInsideSpread(element.x1, element.y1, coords, spreadWidth, spreadHeight) ||
    isPointInsideSpread(element.x2, element.y2, coords, spreadWidth, spreadHeight)
  ) {
    return true;
  }

  // then we check if the line intersect any side of the spread
  return isSegmentIntersectWithSpread(
    [element.x1, element.y1],
    [element.x2, element.y2],
    coords,
    spreadWidth,
    spreadHeight,
  );
}

export function isRectangleElementVisibleInSpread(
  element: Exclude<MediaElement, MediaLine>,
  spreadCoords: Coords,
  spreadWidth: number,
  spreadHeight: number,
) {
  return checkIfRectangleMostlyNotVisibleInSpread(
    {
      left: element.x,
      top: element.y,
      width: element.width,
      height: element.height,
      angle: element.r,
    },
    { x: spreadCoords.left, y: spreadCoords.top },
    spreadWidth,
    spreadHeight,
  );
}

export function checkIfRectangleMostlyNotVisibleInSpread(
  objectRect: ObjectRect,
  spreadCoords: Point,
  spreadWidth: number,
  spreadHeight: number,
) {
  const tl: [number, number] = [objectRect.left, objectRect.top];
  const tr = getPointPositionRotatedOnPoint(
    objectRect.left + objectRect.width,
    objectRect.top,
    objectRect.left,
    objectRect.top,
    objectRect.angle,
  );
  const br = getPointPositionRotatedOnPoint(
    objectRect.left + objectRect.width,
    objectRect.top + objectRect.height,
    objectRect.left,
    objectRect.top,
    objectRect.angle,
  );
  const bl = getPointPositionRotatedOnPoint(
    objectRect.left,
    objectRect.top + objectRect.height,
    objectRect.left,
    objectRect.top,
    objectRect.angle,
  );

  const rectPolygon = [tl, tr, br, bl];

  // Compute total area of the rectangle
  const totalArea = objectRect.width * objectRect.height;

  // Compute visible area of the rectangle inside the spread
  const visibleArea = computeIntersectionArea(rectPolygon, spreadCoords, spreadWidth, spreadHeight);

  // If at least 50% of the rectangle is not visible, return true
  return visibleArea > totalArea * REQUIRED_VISIBLE_AREA_RATE;
}

function getNotVisibleWarning(
  element: MediaElement,
  spreadIndex: number,
  spreadCoords: Coords,
  spreadWidth: number,
  spreadHeight: number,
): NotVisibleWarning | undefined {
  // avoid undefined
  const formattedSpreadCoords = {
    top: spreadCoords.top || 0,
    left: spreadCoords.left || 0,
  };

  if (element.type === 'line') {
    return isLineVisibleInSpread(element, formattedSpreadCoords, spreadWidth, spreadHeight)
      ? undefined
      : {
          type: WarningType.NotVisible,
          uuid: element.uuid,
          spreadIndex,
          level: WarningLevel.Medium,
        };
  }

  return isRectangleElementVisibleInSpread(element, formattedSpreadCoords, spreadWidth, spreadHeight)
    ? undefined
    : {
        type: WarningType.NotVisible,
        uuid: element.uuid,
        spreadIndex,
        level: WarningLevel.Medium,
      };
}

export function computeIntersectionArea(
  rectPolygon: [number, number][],
  spreadCoords: Point,
  spreadWidth: number,
  spreadHeight: number,
): number {
  const spreadPolygon: [number, number][] = [
    [spreadCoords.x, spreadCoords.y], // top-left
    [spreadCoords.x + spreadWidth, spreadCoords.y], // top-right
    [spreadCoords.x + spreadWidth, spreadCoords.y + spreadHeight], // bottom-right
    [spreadCoords.x, spreadCoords.y + spreadHeight], // bottom-left
  ];

  const intersection = polygonClipping.intersection([rectPolygon], [spreadPolygon]);

  // if there's no intersection, return 0
  if (intersection.length === 0) {
    return 0;
  }

  return sumIntersectionArea(intersection);
}

function sumIntersectionArea(multiPolygon: [number, number][][][]): number {
  let totalArea = 0;

  multiPolygon.forEach((polygonGroup) => {
    polygonGroup.forEach((polygon) => {
      totalArea += computePolygonArea(polygon);
    });
  });

  return totalArea;
}

/**
 * Computes the area of a polygon using the shoelace formula.
 */
function computePolygonArea(polygon: [number, number][]): number {
  let area = 0;
  const n = polygon.length;

  for (let i = 0; i < n; i += 1) {
    const [x1, y1] = polygon[i];
    const [x2, y2] = polygon[(i + 1) % n]; // Wrap around for the last point
    area += x1 * y2 - x2 * y1;
  }

  return Math.abs(area) / 2;
}

export default getNotVisibleWarning;
