import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  onClick?: (e: MouseEvent) => void;
  className?: string;
  children?: React.ReactNode;
  black?: boolean;
}

function LinkButton({ children, onClick, className, black = true }: Props) {
  const handleClick: any = (e: MouseEvent) => {
    if (onClick) {
      onClick(e);
    }
    e.stopPropagation();
  };

  return (
    <button className={cn(styles.linkButton, className, { [styles.black]: black })} onClick={handleClick}>
      {children}
    </button>
  );
}

export default LinkButton;
