import cn from 'classnames';
import React, { useState } from 'react';

import Accordion from 'editor/src/component/Accordion';

import styles from './index.module.scss';

interface Props {
  title: string;
  defaultExpand?: true;
  className?: string;
  headerClassName?: string;
  children: React.ReactNode;
}

function CollapsableContainer({ title, className, headerClassName, defaultExpand, children }: Props) {
  const [collapsed, setCollapsed] = useState(!defaultExpand);

  return (
    <div className={cn(styles.CollapsableContainer, className, 'mb-2', 'pl-2', 'pr-2')}>
      <Accordion
        header={title}
        headerClassName={cn(styles.header, headerClassName)}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      >
        {children}
      </Accordion>
    </div>
  );
}

export default React.memo(CollapsableContainer);
