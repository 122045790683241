import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DigitizationColorGuidelines from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/DigitizationGuidelines/DigitizationColorGuidelines';
import IconInfo from 'editor/src/component/Icon/IconInfo';
import RichTooltip from 'editor/src/component/RichTooltip';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

function ThreadColorGuidelines() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [guidelinesTooltipOpen, setGuidelinesTooltipOpen] = useState(false);
  const guidelinesBtnRef = useRef<HTMLDivElement>(null);

  const toggleGuidelinesTooltip = () => {
    setGuidelinesTooltipOpen(!guidelinesTooltipOpen);
  };

  const closeGuidelinesTooltip = () => {
    setGuidelinesTooltipOpen(false);
  };

  if (isMobile) {
    return null;
  }

  return (
    <div className={styles.guidelinesContainer}>
      <div ref={guidelinesBtnRef} onClick={toggleGuidelinesTooltip}>
        <IconInfo className={styles.iconInfo} />
      </div>
      <div>
        <RichTooltip
          title={t('What colors can I use?')}
          isVisible={guidelinesTooltipOpen}
          className={styles.tooltip}
          triggerBtnRef={guidelinesBtnRef}
          onClose={closeGuidelinesTooltip}
          pointerOffset={15}
        >
          <DigitizationColorGuidelines />
        </RichTooltip>
      </div>
    </div>
  );
}

export default React.memo(ThreadColorGuidelines);
